import React, {useEffect, useState} from 'react'

import nordaLogo from "../../assets/img/nordaloft-logo-w170.gif";
import "./navbar.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor } from '@fortawesome/free-solid-svg-icons'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';

const NavBar = () => {
    const [scrollChange, setScrollChange] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeNavbarStyles);

        return () =>
            window.removeEventListener('scroll',changeNavbarStyles);

    }, []);


    const changeNavbarStyles = () => {
        if (window.scrollY >= 80) {
            setScrollChange(true);
        } else {
            setScrollChange(false);
        }
    };

    return (
        <header id="header">
            <Navbar expand='lg' className={scrollChange ? 'navbar scrollChange' : 'navbar'} variant="dark">
                <Container>
                    <Navbar.Brand href="#">
                        <img className="navbar-brand" src={nordaLogo} alt="Norda Loft logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-md`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                        placement="end"
                        className="offcanvas-custom"
                        restoreFocus={false}
                        scroll={true}
                        // style={{backgroundImage: `url(${SideMenuBackground})`}}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                <img style={{maxHeight: "150px"}}className="navbar-brand" src={nordaLogo} alt="Norda Loft logo"/>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href="#about" data-to-scrollspy-id="about">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Norda Loft
                                </Nav.Link>
                                <Nav.Link href="#rooms" data-to-scrollspy-id="rooms">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Pokoje
                                </Nav.Link>
                                <Nav.Link href="#features" data-to-scrollspy-id="features">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Miejsce
                                </Nav.Link>
                                <Nav.Link href="#beach" data-to-scrollspy-id="beach">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Plaża
                                </Nav.Link>
                                <Nav.Link href="#calendar" data-to-scrollspy-id="calendar">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Kalendarz
                                </Nav.Link>
                                <Nav.Link href="#contact" data-to-scrollspy-id="contact">
                                    <FontAwesomeIcon icon={faAnchor} />
                                    Kontakt
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    )
}

export default NavBar;
