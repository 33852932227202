import React from 'react';
import NordaCalendar from "../NordaCalendar/NordaCalendar";

export interface BookingTimeProps {
}

export default function BookingTime(props: BookingTimeProps) {
    return (
        <section id="calendar" className="calendar">
            <div className="container">

                <div className="section-title" data-aos="fade-right" data-aos-delay="250">
                    <h2>Kalendarz Norda Loft</h2>
                    <p>Sprawdź dostępność</p>
                </div>
                <NordaCalendar />
            </div>
        </section>
    );
}