import React from 'react';
import './footer.scss';

export type FooterProps = {}

export default function Footer({}: FooterProps) {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-info">
                                <h3>
                                    <span>NORDA LOFT</span>
                                </h3>
                                <p>
                                    Akacjowa 31, <br/>
                                    Osiedle Norda, Dębki<br/><br/>
                                    <strong>Telefon:</strong> 502555666<br/>
                                    <strong>Email:</strong> nordaloft@gmail.com<br/>
                                </p>
                                <div className="social-links">
                                    <a href="#" className="bi-facebook">
                                        <i className="bx bxl-facebook"></i>
                                    </a>
                                    <a href="#" className="whatsapp">
                                        <i className="bi-whatsapp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    {/*    <div className="col-lg-3 col-md-6 footer-links">*/}
                    {/*            <Nav className="">*/}
                    {/*                <Nav.Link href="#about" data-to-scrollspy-id="about">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Norda Loft*/}
                    {/*                </Nav.Link>*/}
                    {/*                <Nav.Link href="#rooms" data-to-scrollspy-id="rooms">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Pokoje*/}
                    {/*                </Nav.Link>*/}
                    {/*                <Nav.Link href="#features" data-to-scrollspy-id="features">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Miejsce*/}
                    {/*                </Nav.Link>*/}
                    {/*                <Nav.Link href="#beach" data-to-scrollspy-id="beach">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Plaża*/}
                    {/*                </Nav.Link>*/}
                    {/*                <Nav.Link href="#calendar" data-to-scrollspy-id="calendar">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Kalendarz*/}
                    {/*                </Nav.Link>*/}
                    {/*                <Nav.Link href="#contact" data-to-scrollspy-id="contact">*/}
                    {/*                    <FontAwesomeIcon icon={faAnchor} />*/}
                    {/*                    Kontakt*/}
                    {/*                </Nav.Link>*/}
                    {/*            </Nav>*/}
                    {/*    </div>*/}
                    </div>
                </div>
            </div>
        </footer>
    );
}
