import React, {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import {addDays, differenceInCalendarDays, isSameDay} from 'date-fns';

import 'react-calendar/dist/Calendar.css';
import _ from 'lodash';
import './nordaCallendar.scss';

export type NordaCalendarProps = {}

export default function NordaCalendar({}: NordaCalendarProps) {
    const [date, setDate] = useState(new Date());
    const [isMobile, setIsMobile] = useState(false)

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const datesToAddClassTo = ["2024-07-13","2024-07-14","2024-07-15","2024-07-16","2024-07-17","2024-07-18","2024-07-18","2024-07-19","2024-07-19","2024-07-20","2024-07-20","2024-07-21","2024-07-22","2024-07-23","2024-07-24","2024-07-25","2024-07-26","2024-07-27","2024-07-28","2024-07-29","2024-07-30","2024-07-31","2024-08-01","2024-08-02","2024-08-03","2024-08-04","2024-08-05","2024-08-06","2024-08-07","2024-08-08","2024-08-09","2024-08-10","2024-08-10","2024-08-11","2024-08-11","2024-08-12","2024-08-12","2024-08-13","2024-08-13","2024-08-14","2024-08-14","2024-08-15","2024-08-15","2024-08-16","2024-08-16","2024-08-17","2024-08-17","2024-08-18","2024-08-18","2024-08-19","2024-08-19","2024-08-20","2024-08-20","2024-08-21","2024-08-21","2024-08-22","2024-08-22","2024-08-23","2024-08-23","2024-09-06","2024-09-06","2024-09-07","2024-09-07","2024-09-08","2024-09-08","2024-09-09","2024-09-09","2024-09-10","2024-09-10","2024-09-11","2024-09-11","2024-12-01","2024-12-02","2024-12-03","2024-12-04","2024-12-05","2024-12-06","2024-12-07","2024-12-08","2024-12-09","2024-12-10","2024-12-11","2024-12-12","2024-12-13","2024-12-14","2024-12-15","2024-12-16","2024-12-17","2024-12-18","2024-12-19","2024-12-20","2024-12-21","2024-12-22","2024-12-23","2024-12-24","2024-12-25","2024-12-26","2024-12-27","2024-12-28","2024-12-29","2024-12-30","2024-12-31"];

    function tileClassName({date, view}: { date: Date, view: string }) {
        if (
            view === 'month' &&
            datesToAddClassTo.find(
                (dateString) => isSameDay(new Date(dateString), date)
            )
        ) {
            return 'noBookingTime';
        }

        return null;
    }

    function tileContent({date, view}: { date: Date, view: string }) {
        // Add class to tiles in month view only
        if (view === 'month') {

            // Check if a date React-Calendar wants to check is on the list of dates to add class to
            if (_.has(datesToAddClassTo, date.getMonth())) {
                return <p>---</p>;
            }
        }

        return null;
    }

    return (
        <div className='d-flex justify-content-center' data-aos="fade-up" data-aos-delay="250">
            <Calendar
                onChange={setDate}
                value={date}
                selectRange={false}
                tileClassName={tileClassName}
                // tileContent={tileContent}
            />
        </div>
    );

}
