import React from "react";
import {Zoom, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Beach1 from "../../assets/img/plaza-1-w750.jpg";
import Beach2 from "../../assets/img/plaza-2-w750.jpg";
import Beach3 from "../../assets/img/plaza-3-w750.jpg";
import Beach4 from "../../assets/img/plaza-4-w750.jpg";
import Beach5 from "../../assets/img/plaza-5-w750.jpg";
import Beach6 from "../../assets/img/plaza-6-w750.jpg";
import Beach7 from "../../assets/img/plaza-7-w750.jpg";
import Beach8 from "../../assets/img/plaza-8-w750.jpg";
import Beach9 from "../../assets/img/plaza-9-w750.jpg";
import Beach10 from "../../assets/img/plaza-10-w750.jpg";
import Beach11 from "../../assets/img/plaza-11-w750.jpg";

import './gallerySlider.scss';

export type GallerySliderProps = {}

export default function GallerySlider() {
    return (
        <section id="beach" className="beach">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Plaża</h2>
                    <p>Najszersza plaża w Polsce</p>
                </div>
            </div>
            <Swiper
                zoom={true}
                // slidesPerView={3}
                spaceBetween={30}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Zoom, Navigation, Pagination]}
                className="nordaSwiper"
            >

                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach9}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach7}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach8}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach1}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach2}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach10}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach11}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach3}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach4}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach5}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-zoom-container">
                        <img src={Beach6}/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
        ;
}
