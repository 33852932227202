import React from 'react';

import "./parallaxShort.scss";
import ParallaxImg from "../../assets/img/plaza-3.jpg";
export type ParallaxShortProps = {}

const styles = {
    background: `linearGradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url(${ParallaxImg})`,
    backgroundSize: "cover",
    padding: "60px 0"
}

export default function ParallaxShort({}: ParallaxShortProps) {
    return (
        <section id="cta" className="cta" style={{backgroundImage: `url(${ParallaxImg})`}}>
            <div className="container" data-aos="zoom-in">

                <div className="text-center">
                    <h3>Pozostały jeszcze wolne terminy</h3>
                    <a className="cta-btn" href="#calendar">Sprawdź kalendarz</a>
                </div>
             </div>
         </section>
    );
}
