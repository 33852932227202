import React from 'react';

import "./features.scss";
import NordaView from "../../assets/img/norda-view-1-w700.jpg"
import {ReactComponent as SwingSet} from "../../assets/img/swing-set-icon.svg";
import {ReactComponent as Forest} from "../../assets/img/forest-tree-icon.svg";
import {ReactComponent as Scooter} from "../../assets/img/kick-scooter-icon.svg";
import {ReactComponent as Camera} from "../../assets/img/cctv-icon.svg";
import {ReactComponent as Parking} from "../../assets/img/parking-icon.svg";
import {ReactComponent as Barbecue} from "../../assets/img/bbq-icon.svg";

export type FeaturesProps = {}

export default function Features({}: FeaturesProps) {
    return (
        <section id="features" className="features">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Położona w sercu Osiedla Norda</h2>
                    <p>Wyjątkowe miejsce</p>
                </div>
                <div className="row">
                    <div className="image col-lg-6"
                         style={{backgroundImage: `url(${NordaView})`}}
                         data-aos="fade-right"></div>
                    <div className="col-lg-6">
                        <div className="row" data-aos="fade-left" data-aos-delay="450">
                            <div className="col-lg-2 icon-box">
                                <Scooter />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Na terenie osiedla znajdują się bezpieczne alejki do jazdy na rowerze lub
                                    hulajnodze</h4>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-left" data-aos-delay="550">
                            <div className="col-lg-2 icon-box">
                                <Forest />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Ogólnodostępny lasek z ławkami i alejkami</h4>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-left" data-aos-delay="650">
                            <div className="col-lg-2 icon-box">
                                <SwingSet />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Plac zabaw dla najmłodszych (domek, piaskownica, bujak, karuzela z drabinkami, zjeżdżalnia, huśtawki)</h4>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-left" data-aos-delay="750">
                            <div className="col-lg-2 icon-box">
                                <Barbecue />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Bezpośrednio przy domku jest prywatny ogród oraz taras z kompletem wypoczynkowym oraz grill</h4>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-left" data-aos-delay="850">
                            <div className="col-lg-2 icon-box">
                                <Parking />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Przed domem znajduje się prywatne miejsce parkingowe.</h4>
                            </div>
                        </div>
                        <div className="row" data-aos="fade-left" data-aos-delay="950">
                            <div className="col-lg-2 icon-box">
                                <Camera />
                            </div>
                            <div className="col-lg-10 icon-text">
                                <h4>Okolica domku jest monitorowana</h4>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </section>
    );
}
