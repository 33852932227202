import React from 'react';

import "./parallaxWide.scss";
import ParallaxImg from "../../assets/img/plaza-1.jpg";
export type ParallaxWideProps = {}

const styles = {
    background: `linearGradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url(${ParallaxImg})`,
    backgroundSize: "cover",
    padding: "60px 0"
}

export default function ParallaxWide({}: ParallaxWideProps) {
    return (
        <section id="cta" className="cta" style={{backgroundImage: `url(${ParallaxImg})`}}>
            <div className="container" data-aos="zoom-in">

                <div className="text-center">
                    <h3>Rezerwując teraz przez stronę otrzymasz zniżkę</h3>
                    <a className="cta-btn" href="#calendar">Zarezerwuj telefonicznie</a>
                </div>
             </div>
         </section>
    );
}
