import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

//initialize aos library for presenting stuff
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/styles/styles.scss';

import FirstPage from "./components/FirstPage/FirstPage";
import About from "./components/About/About";
import ParallaxWide from "./components/ParallaxWide/ParallaxWide";
import SwitchGallery from "./components/SwitchGallery/SwitchGallery";
import ParallaxShort from "./components/ParallaxShort/ParallaxShort";
import Features from "./components/Features/Features";
import BookingTime from "./components/BookingTime/BookingTime";
import Contact from "./components/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";

import ScrollSpy from "react-ui-scrollspy";
import GallerySlider from "./components/GallerySlider/GallerySlider";
import ScrollUpButton from "./components/ScrollUpButton/ScrollUpButton";
import Footer from "./components/Footer/Footer";
import nordaLogo from "./assets/img/norda-logo.svg";

AOS.init();
interface AppProps {

}
interface AppState {
    loadedImages: number;
}
class App extends React.Component<AppProps, AppState> {
    private totalImagesToLoad: number = 1;
    constructor(props: AppProps) {
        super(props);
        this.state = {loadedImages: 0};
    }
    componentDidMount() {

    }

    componentDidUpdate() {

    }

    handleImageLoaded = () => {
        this.setState((prevState) => ({
            loadedImages: prevState.loadedImages + 1
        }));
    }

    render() {

        const {loadedImages} = this.state;

        const isContentLoaded = loadedImages >= this.totalImagesToLoad;

        return (
            <>
                {(!isContentLoaded &&
                        <div className="logo-container">
                            <img
                                className="welcome-logo"
                                src={nordaLogo}
                                alt="Norda Loft logo"
                            />
                        </div>
                    )
                    || <Navbar/>

                }
                <FirstPage handleImageLoaded={this.handleImageLoaded}/>
                {isContentLoaded && (
                    <>
                    <ScrollSpy>
                        <About />
                        <ParallaxWide/>
                        <SwitchGallery/>
                        <ParallaxShort/>
                        <Features/>
                        <GallerySlider/>
                        <BookingTime/>
                        <Contact/>
                        <Footer/>
                        <ScrollUpButton/>
                    </ScrollSpy>
                    </>
                )}

            </>
        );
    }
}

export default App;