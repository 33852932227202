import React from 'react';
import './contact.scss';

export type ContactProps = {}

export default function Contact({}: ContactProps) {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Kontakt</h2>
                    <p>Skontaktuj się z nami</p>
                </div>

                <div>
                    <iframe style={{border:0, width: "100%", height: "270px"}}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2298.453362061384!2d18.074338616070342!3d54.82472027064968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fdcdf34fe5eeeb%3A0x951a2b892cedfa54!2sNorda%20Loft!5e0!3m2!1spl!2spl!4v1677594635413!5m2!1spl!2spl"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className="container info">
                    <div className="row gx-5">
                        <div className="col-md-4">
                            <div className="address">
                                <i className="bi bi-geo-alt"></i>
                                <h4>Adres:</h4>
                                <p>Akacjowa 31, Dębki, Osiedle Norda</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="e-mail">
                                <i className="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p>nordaloft@gmail.com</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="moblie">
                                <i className="bi bi-phone"></i>
                                <h4>Telefon:</h4>
                                <p>502 555 666</p>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </section>
);
}
