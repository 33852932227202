import React from 'react';
import Isotope from "isotope-layout";
import './switchGallery.scss';

import RoomOne1 from "../../assets/img/pokoj1-1-w450.jpg";
import RoomOne2 from "../../assets/img/pokoj1-2-w450.jpg";
import RoomOne3 from "../../assets/img/pokoj1-3-w450.jpg";
import Saloon1 from "../../assets/img/salon1-w450.jpg";
import Saloon6 from "../../assets/img/salon6-w450.jpg";
import RoomTwo1 from "../../assets/img/pokoj2-1-w450.jpg";
import RoomTwo2 from "../../assets/img/pokoj2-2-w450.jpg";
import RoomThree1 from "../../assets/img/pokoj3-1-w450.jpg";
import Kitchen1 from "../../assets/img/kuchnia1-w450.jpg";
import Kitchen2 from "../../assets/img/kuchnia2-w450.jpg";
import Kitchen3 from "../../assets/img/kuchnia3-w450.jpg";
import House1 from "../../assets/img/house1-w450.jpg";
import House2 from "../../assets/img/taras-1-w450.jpg";
import House4 from "../../assets/img/taras-2-w450.jpg";
import Saloon9 from "../../assets/img/salon9-w450.jpg";
import BathroomOne1 from "../../assets/img/lazienka1-1-w450.jpg";
import BathroomOne4 from "../../assets/img/lazienka1-4-w450.jpg";
import BathroomTwo1 from "../../assets/img/lazienka2-1-w450.jpg";
import BathroomTwo2 from "../../assets/img/lazienka2-2-w450.jpg";
import BathroomTwo3 from "../../assets/img/lazienka2-3-w450.jpg";
import BathroomTwo4 from "../../assets/img/lazienka2-4-w450.jpg";

export default class SwitchGallery extends React.Component<any, any> {
    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            isotope: null,
            filterKey: '*',
        };
    }

    componentDidMount() {
        // init Isotope
        let isotope = new Isotope(
            '.portfolio-container', {
                itemSelector: '.portfolio-item',
            });

        isotope.destroy();

        this.setState({
            isotope: isotope
        });
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        const { filterKey, isotope } = this.state;

        if (prevState.filterKey !== this.state.filterKey) {
            if (filterKey === '*') {
                isotope.arrange({filter: `*`})
            } else {
                isotope.arrange({filter: `.${filterKey}`});
            }
        }
    }

    handleFilterKeyChange = (key: string) => {
        this.setState({filterKey: key});
    }

    getClassName(type: string) {
        const {filterKey} = this.state;

        if (type === filterKey) {
            return 'filter-active';
        }

        return '';
    }

    render() {
        return (
            <section id="rooms" className="portfolio">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Pokoje</h2>
                        <p>Odkryj wnętrze Norda Loft</p>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="*"
                                    className={this.getClassName('*')}>Wszystkie
                                </button>
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="filter-rooms"
                                    className={this.getClassName('filter-rooms')}>Pokoje
                                </button>
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="filter-saloon"
                                    className={this.getClassName('filter-saloon')}>Salon
                                </button>
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="filter-kitchen"
                                    className={this.getClassName('filter-kitchen')}>Kuchnia
                                </button>
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="filter-bathroom"
                                    className={this.getClassName('filter-bathroom')}>Łazienki
                                </button>
                                <button
                                    onClick={(e) => this.handleFilterKeyChange(String(e.currentTarget?.getAttribute('data-filter')))}
                                    data-filter="filter-garden"
                                    className={this.getClassName('filter-garden')}>Ogród
                                </button>
                            </ul>
                        </div>
                    </div>

                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomOne2} className="img-fluid" alt="Pierwsza sypialnia"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 1</h4>
                                    <p>Pierwsza sypialnia z łożem małżeńskim 160/200cm, biurkiem na laptopa bądź
                                        toaletką oraz
                                        szafą.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-kitchen">
                            <div className="portfolio-wrap">
                                <img src={Kitchen1} className="img-fluid" alt="Kuchnia w stylu loftowym"/>
                                <div className="portfolio-info">
                                    <h4>Kuchnia w stylu Loft</h4>
                                    <p>Do dyspozycji gości jest kompletnie wyposażona kuchnia - zmywarka, płyta
                                        indukcyjna,
                                        okap, ekspres do kawy firmy BOSH (pasują do niego kapsułki firmy Tassimo), chłodziarko-zamrażarka, kuchenka mikrofalowa,
                                        czajnik
                                        bezprzewodowy, naczynia, sztućce, garnki, akcesoria kuchenne</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-saloon">
                            <div className="portfolio-wrap">
                                <img src={Saloon6} className="img-fluid" alt="Salon - wyjście na taras"/>
                                <div className="portfolio-info">
                                    <h4>Salon - widok na taras</h4>
                                    <p>Z salonu można wyjść na taras, gdzie dostępny jest komplet wypoczynkowy,
                                        a także prywatny
                                        ogród.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomTwo2} className="img-fluid" alt="Łazienka na piętrze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na piętrze</h4>
                                    <p>Łazienka na piętrze - toaleta, zlew, duża kabina prysznicowa</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomOne4} className="img-fluid" alt="Łazienka na parterze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na parterze</h4>
                                    <p>Łazienka na parterze - toaleta, zlew, duża kabina prysznicowa</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomTwo4} className="img-fluid" alt="Łazienka na piętrze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na piętrze</h4>
                                    <p>Łazienka na piętrze - toaleta, zlew, duża kabina prysznicowa</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomTwo1} className="img-fluid" alt="Druga sypialnia"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 2</h4>
                                    <p>Druga sypialnia wyposażona została w łoże małżeńskie 140/200cm i
                                        szafę.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-kitchen">
                            <div className="portfolio-wrap">
                                <img src={Kitchen3} className="img-fluid" alt="Kuchnia w stylu loftowym"/>
                                <div className="portfolio-info">
                                    <h4>Kuchnia w stylu Loft</h4>
                                    <p>Do dyspozycji gości jest kompletnie wyposażona kuchnia - zmywarka, płyta
                                        indukcyjna,
                                        okap, ekspres do kawy firmy BOSH (pasują do niego kapsułki firmy Tassimo), chłodziarko-zamrażarka, kuchenka mikrofalowa,
                                        czajnik
                                        bezprzewodowy, naczynia, sztućce, garnki, akcesoria kuchenne</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-saloon">
                            <div className="portfolio-wrap">
                                <img src={Saloon1} className="img-fluid" alt="Salon - kominek"/>
                                <div className="portfolio-info">
                                    <h4>Salon - widok na kominek</h4>
                                    <p>W salonie znajduje się telewizor, stół kawowy, rozkładana sofa a także
                                        kominek
                                        elektryczny, który tworzy przyjemny klimat także w letnie wieczory, a w
                                        zimie może być
                                        również dodatkowym źródłem ciepła (obok ogrzewania podłogowego).</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomThree1} className="img-fluid" alt="Sypialnie trzecia na piętrze"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 3</h4>
                                    <p>Sypialnia trzecia posiada dwa łóżka piętrowe - dolne o wymiarach 120/200,
                                        górne 90/200,
                                        szafa oraz komoda z szufladami.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-kitchen">
                            <div className="portfolio-wrap">
                                <img src={Kitchen2} className="img-fluid" alt="Kuchnia"/>
                                <div className="portfolio-info">
                                    <h4>Kuchnia - przejście - jadalnia</h4>
                                    <p>Kuchnia jest wpełni wyposażona, a dodatki wraz z oświetleniem tworzą
                                        przyjemny loftowy
                                        klimat. Obok kuchni na gości czeka szeroki stół który pomieści 6
                                        osób.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-garden">
                            <div className="portfolio-wrap">
                                <img src={House1} className="img-fluid" alt="Widok na ogród i taras"/>
                                <div className="portfolio-info">
                                    <h4>Widok na ogród i taras</h4>
                                    <p>Bezpośrednio przy domku jest prywatny ogród oraz taras z kompletem
                                        wypoczynkowym, również
                                        grill.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-saloon">
                            <div className="portfolio-wrap">
                                <img src={Saloon9} className="img-fluid" alt="Taras z wypoczynkiem"/>
                                <div className="portfolio-info">
                                    <h4>Salon - wypoczynek</h4>
                                    <p>W salonie dostępna jest sofa z funkcją spania, stół, krzesła, stolik
                                        kawowy, telewizor
                                        oraz kominek elektryczny</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomOne1} className="img-fluid" alt="Łazienka na parterze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na parterze</h4>
                                    <p>Łazienka na parterze - toaleta, zlew, duża kabina prysznicowa</p>
                               </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomTwo1} className="img-fluid" alt="Łazienka na piętrze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na piętrze</h4>
                                    <p>Łazienka na piętrze - toaleta, zlew, duża kabina prysznicowa</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomOne1} className="img-fluid" alt="Pierwsza sypialnia"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 1</h4>
                                    <p>Pierwsza sypialnia z łożem małżeńskim 160/200cm, biurkiem na laptopa bądź
                                        toaletką oraz
                                        szafą.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-garden">
                            <div className="portfolio-wrap">
                                <img src={House2} className="img-fluid" alt="Widok na ogród i taras"/>
                                <div className="portfolio-info">
                                    <h4>Widok na ogród i taras</h4>
                                    <p>Bezpośrednio przy domku jest prywatny ogród oraz taras z kompletem
                                        wypoczynkowym, również
                                        grill.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomOne3} className="img-fluid" alt="Pierwsza sypialnia"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 1</h4>
                                    <p>Pierwsza sypialnia z łożem małżeńskim 160/200cm, biurkiem na laptopa bądź
                                        toaletką oraz
                                        szafą.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-rooms">
                            <div className="portfolio-wrap">
                                <img src={RoomTwo2} className="img-fluid" alt="Druga sypialnia"/>
                                <div className="portfolio-info">
                                    <h4>Pokój nr 2</h4>
                                    <p>Druga sypialnia wyposażona została w łoże małżeńskie 140/200cm i
                                        szafę.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-bathroom">
                            <div className="portfolio-wrap">
                                <img src={BathroomTwo3} className="img-fluid" alt="Łazienka na piętrze"/>
                                <div className="portfolio-info">
                                    <h4>Łazienka na piętrze</h4>
                                    <p>Łazienka na piętrze - toaleta, zlew, duża kabina prysznicowa</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-garden">
                            <div className="portfolio-wrap">
                                <img src={House4} className="img-fluid" alt="Widok na ogród i taras"/>
                                <div className="portfolio-info">
                                    <h4>Widok na ogród i taras</h4>
                                    <p>Bezpośrednio przy domku jest prywatny ogród oraz taras z kompletem
                                        wypoczynkowym, również
                                        grill.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}