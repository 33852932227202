import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './scrollUpButton.scss';

const ScrollUpButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const styles = {
        display: visible ? 'flex' : 'none',
        opacity: visible ? 0.8 : 0
    };

    return (
        <a
            className="backToTop"
            onClick={scrollToTop}
            style={styles}
        >
            <FaArrowCircleUp />
        </a>
    );
}

export default ScrollUpButton;